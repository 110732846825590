import {component} from 'picoapp';

export default component((node, ctx) => {
  const state = {
    activeTabIndex: -1,
    maxIndex: 0
  };

  // Get all the <h3> headings
  const headings = [...node.querySelectorAll('h3')];

  // Function to create a node list
  // of the content between this <h3> and the next
  const getContent = el => {
    let els = [];

    while (el.nextElementSibling && el.nextElementSibling.tagName !== 'H3') {
      els.push(el.nextElementSibling);
      el = el.nextElementSibling;
    }

    // Delete the old versions of the content nodes
    els.forEach(node => {
      node.parentNode.removeChild(node);
    });

    return els;
  };

  let initialPanels = [];

  // Find all the content adjacent to each H3 and wrap in a div
  headings.forEach(heading => {
    const contents = getContent(heading);
    const wrapper = document.createElement('div');

    contents.forEach(el => {
      wrapper.appendChild(el);
    });

    initialPanels.push(wrapper);
  });

  const trigger = (text, index) => {
    return `
      <button class="tabs__button" data-index="${index}" data-element="trigger-button" role="tab">${text}</button>
    `;
  };

  const panel = (html, index) => {
    return `
      <div class="[ tabs__panel ] [ flow ]" data-index="${index}" data-element="tab-panel">${html}</div>
    `;
  };

  const template = `
    <ul role="tablist" class="tabs__triggers">
      ${headings
        .map((item, index) => `<li>${trigger(item.innerText, index)}</li>`)
        .join('')}
    </ul>
     ${initialPanels.map((item, index) => panel(item.innerHTML, index)).join('')}
  `;

  node.innerHTML = template;

  // Assign newly rendered items
  const triggers = node.querySelectorAll('[data-element="trigger-button"]');
  const panels = node.querySelectorAll('[data-element="tab-panel"]');

  // Remove the triggers and bail out if there's a mismatch of triggers and panels.
  // It’s better to leave the element mostly like it was, rather than have a broken component
  if (triggers.length !== panels.length) {
    triggers.forEach(trigger => trigger.parentNode.removeChild(trigger));
    return;
  }

  // We know we're all good so set the first item as active and set max index
  state.maxIndex = triggers.length - 1;
  toggle(0, true);

  // Now set each trigger’s events
  triggers.forEach((trigger, triggerIndex) => {
    // On click, show this trigger’s panel
    trigger.addEventListener('click', e => {
      e.preventDefault();

      toggle(triggerIndex);
    });

    trigger.addEventListener('keydown', e => {
      switch (e.keyCode) {
        case 39:
          modifyIndex('up', triggerIndex);
          break;
        case 37:
          modifyIndex('down', triggerIndex);
          break;
        case 40:
          // If the down key is pressed, we want to focus on the active panel
          panels[triggerIndex].focus();
          break;
      }
    });
  });

  // Lastly, set the panel’s events
  panels.forEach(panel => {
    panel.addEventListener('keydown', e => {
      switch (e.keyCode) {
        case 38:
          // When the user keys up (when this is focused), focus on it's related tab
          triggers[state.activeTabIndex].focus();
          break;
      }
    });
  });

  function toggle(index, isInitial = false) {
    // If the passed index is the same as the active index
    // we don't need to do anything, so bail
    if (index === state.activeTabIndex) {
      return;
    }

    // If the index has been exceeded, let's just set it to
    // the maxIndex
    if (index > state.maxIndex) {
      index = state.maxIndex;
    }

    state.activeTabIndex = index;

    triggers.forEach((trigger, triggerIndex) => {
      const panel = panels[triggerIndex];

      if (triggerIndex === index) {
        trigger.setAttribute('aria-selected', 'true');

        // Remove the tabindex override so it can be focussed with
        // the keyboard again
        trigger.removeAttribute('tabindex');

        // Focus this trigger if this isn’t the initial toggle
        // because other triggers are now focus-proof
        if (!isInitial) {
          trigger.focus();
        }

        // Show the panel and add a tabindex so it can be
        // programatically focused
        panel.setAttribute('data-state', 'visible');
        panel.setAttribute('tabindex', '-1');
      } else {
        // Remove selected state and allow it to be keyboard focused again
        trigger.removeAttribute('aria-selected');
        trigger.setAttribute('tabindex', '-1');

        // Set the panel as hidden and remove tabindex so it can't be
        // programatically focused
        panel.setAttribute('data-state', 'hidden');
        panel.removeAttribute('tabindex');
      }
    });
  }

  function modifyIndex(direction, triggerIndex) {
    // We only modify index if we are focused on the active tab or
    // it'll be a confusing user experience
    if (triggerIndex !== state.activeTabIndex) {
      return;
    }

    switch (direction) {
      case 'down':
        toggle(state.activeTabIndex <= 0 ? state.maxIndex : state.activeTabIndex - 1);
        break;
      case 'up':
        toggle(state.activeTabIndex >= state.maxIndex ? 0 : state.activeTabIndex + 1);
        break;
    }
  }
});

import {component} from 'picoapp';
import {debounce} from '../helpers/debounce';

export default component((node, ctx) => {
  const openMultiple = true;

  let accordionTriggers = null;
  let accordionPanels = null;

  const initAccordion = () => {
    const accordionHeadings = [...node.querySelectorAll('li > h2')];

    accordionHeadings.forEach((heading, i) => {
      heading.innerHTML = `
        <button
          id="header-${i}"
          class="[ accordion__header ] [ splitter md:text-600 ]"
          aria-expanded="false"
          aria-controls="panel-${i}"
          data-index="${i}"
          data-element="accordion-trigger"
        >
          ${heading.textContent}
          <span class="[ closer ] [ icon ]" aria-hidden="true"></span>
        </button>
      `;

      const panelContents = getAccordionContent(heading);
      const panel = document.createElement('div');

      panel.classList.add('accordion__panel', 'flow');
      panel.setAttribute('role', 'region');
      panel.setAttribute('aria-labelledby', `header-${i}`);
      panel.setAttribute('id', `panel-${i}`);
      panel.setAttribute('data-state', 'closed');
      panel.setAttribute('data-element', 'accordion-panel');

      panelContents.forEach(node => {
        panel.appendChild(node);
      });

      heading.parentNode.insertBefore(panel, heading.nextElementSibling);
    });

    accordionTriggers = [...node.querySelectorAll('[data-element="accordion-trigger"]')];
    accordionPanels = [...node.querySelectorAll('[data-element="accordion-panel"]')];

    initEvents(accordionTriggers);
    handleResize();
  };

  const getAccordionContent = el => {
    let panels = [];

    while (el.nextElementSibling && el.nextElementSibling.tagName !== 'H2') {
      panels.push(el.nextElementSibling);
      el = el.nextElementSibling;
    }

    panels.forEach(node => {
      node.parentNode.removeChild(node);
    });

    return panels;
  };

  const handleClick = e => {
    const header = e.currentTarget;
    const index = parseInt(header.dataset.index);
    const panel = accordionPanels[index];
    const otherHeaders = removeIndexFromArray(accordionTriggers, index);
    const otherPanels = removeIndexFromArray(accordionPanels, index);

    if (panel.matches('[data-state="closed"]')) {
      expand(header, panel);

      if (openMultiple) return;

      otherHeaders.forEach((header, i) => {
        collapse(header, otherPanels[i]);
      });
    } else {
      collapse(header, panel);
    }
  };

  const handleResize = () => {
    accordionPanels.forEach((panel, i) => {
      panel.style.setProperty('--panel-height', panel.scrollHeight + 'px');
    });
  };

  const expand = (header, panel) => {
    header.setAttribute('aria-expanded', true);
    panel.setAttribute('data-state', 'open');
  };

  const collapse = (header, panel) => {
    header.setAttribute('aria-expanded', false);
    panel.setAttribute('data-state', 'closed');
  };

  const removeIndexFromArray = (array, i) => {
    const left = array.slice(0, i);
    const right = array.slice(i + 1);

    return left.concat(right);
  };

  const initEvents = trigger => {
    for (let i = 0; i < trigger.length; i++) {
      trigger[i].addEventListener('click', handleClick);
    }

    window.addEventListener('resize', debounce(handleResize, 100));
  };

  initAccordion();
});

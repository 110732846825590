import Highway from '@dogstudio/highway';
import Fade from './transitions/fade';
import Panel from './transitions/panel';
import ToProject from './transitions/toProject';

export default new Highway.Core({
  renderers: {},
  transitions: {
    default: Fade,
    contextual: {
      panel: Panel,
      toProject: ToProject
    }
  }
});

/**
 * A helper function that passes the provided value to the provided function.
 * If an array is provided, the function will be fired for each item in the
 * array.
 *
 * @param  {*}          x a value or array of values
 * @param  {function}   fn the function to call
 * @return {null}
 */
export function call(x, fn) {
  if (Array.isArray(x)) {
    for (let i = 0; i < x.length; i++) fn(x[i]);
  } else {
    return fn(x);
  }
}

/**
 * A factory function that creates an event listener util
 *
 * @param  {string}     action 'add' or 'remove'
 * @return {function}
 */
function events(action) {
  return (x, e, fn) => call(x, x => x[`${action}EventListener`](e, fn));
}

/**
 * Add an event listener to one elements or an array of elements.
 * Conveniently returns unsubscribe function.
 *
 * @param  {*}           x an element or an array of elements
 * @param  {string}      ev event type
 * @param  {function}    cb callback function
 * @return {function}
 */
export function on(x, ev, cb) {
  events('add')(x, ev, cb);
  return () => events('remove')(x, ev, cb);
}

/**
 * Remove all instances of a class
 *
 * @param {array}  el an array of elements
 * @param {string} cn classname to remove
 * @returns
 */
export function removeClasses(el, cn) {
  for (let i = 0; i < el.length; i++) {
    el[i].classList.remove(cn);
  }
}

/**
 * Remove all instances of an attribute
 * from a matching element
 *
 * @param {array} el an array of elements
 * @param {obj} attrs attribute to remove
 */
export function removeAttributes(el, attrs) {
  for (let i = 0; i < el.length; i++) {
    for (var key in attrs) {
      el[i].removeAttribute(key, attrs[key]);
    }
  }
}

/**
 * Get dimensions of viewport
 *
 * @return {object}
 */
export function size() {
  return {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  };
}

/**
 * Get the index of the provided element amongst it's siblings
 *
 * @param {HTMLElement}
 * @return {number}
 */
export function index(el) {
  return Array.from(el.parentNode.children).indexOf(el);
}

/**
 * Clamp a value between two bounds
 *
 * @param  {number} v   Value to clamp
 * @param  {number} min Minimum boundary
 * @param  {number} max Maximum boundary
 * @return {number}     Clamped value
 */
export let clamp = (value, min = 0, max = 1) =>
  value < min ? min : value > max ? max : value;

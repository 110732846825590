import app from './app';
import router from './router';
import * as quicklink from 'quicklink';
import {on, size} from './helpers/dom';
import gsap from 'gsap';

// Get all menu links
const mobileLinks = document.querySelectorAll('[data-link="mobile"]');

// Broadcast window resize events
on(window, 'resize', resize);

// Setup global raf loop
gsap.ticker.add(tick);

// Mount picoapp
app.mount();

// Propagate an initial resize event
resize();

// Listen for page transitions and mount new components
router.on('NAVIGATE_IN', ({location}) => {
  // Check Active Link
  for (let i = 0; i < mobileLinks.length; i++) {
    const link = mobileLinks[i];

    // Clean attribute
    link.removeAttribute('aria-current');

    // set Active attribute
    if (link.href === location.href) {
      link.setAttribute('aria-current', 'page');
    }
  }

  // const panelLinks = to.view.querySelectorAll('.js-tab');
  // for (let i = 0; i < panelLinks.length; i++) {
  //   const link = panelLinks[i];

  //   link.classList.add('texture');

  //   if (link.href === location.href) {
  //     link.classList.remove('texture');
  //   }
  // }

  app.unmount();
  app.mount();
  resize();
});

router.on('NAVIGATE_END', ({to}) => {
  quicklink.listen({
    el: to.view
  });
});

function resize() {
  app.emit('resize', size());
}

function tick() {
  app.emit('tick');
}

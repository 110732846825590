import {component} from 'picoapp';
// import { visibleLinks } from '../helpers/trapFocus';

export default component(node => {
  const menuBtn = [...document.querySelectorAll('.js-menu-btn')];
  let isOpen = false;
  console.log(isOpen);

  function openDrawer() {
    const firstMenuLink = visibleLinks(node)[0];

    isOpen = true;
    for (let i = 0; i < menuBtn.length; i++) {
      menuBtn[i].setAttribute('aria-expanded', true);
    }
    firstMenuLink.focus();

    // setTimeout(() => {
    node.classList.add('drawer--is-visible');
    // }, 100);
  }

  function closeDrawer() {
    node.classList.remove('drawer--is-visible');

    // setTimeout(() => {
    isOpen = false;
    for (let i = 0; i < menuBtn.length; i++) {
      menuBtn[i].setAttribute('aria-expanded', false);
    }
    // }, 250);
  }

  function toggleDrawer() {
    if (!isOpen) {
      console.log('is no open', isOpen);
      openDrawer();
    } else {
      console.log('is open', isOpen);
      closeDrawer();
    }
  }

  function initDrawer() {
    for (let i = 0; i < menuBtn.length; i++) {
      menuBtn[i].addEventListener('click', toggleDrawer);
    }
  }

  initDrawer();
});

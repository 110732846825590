import {component} from 'picoapp';
import {index} from '../helpers/dom';

export default component((node, ctx) => {
  if (!'IntersectionObserver' in window) return;

  const gallery = node.querySelector('[aria-label="gallery"]');
  const slides = gallery.querySelectorAll(':scope > div');

  const setAttributes = els => {
    els.forEach((el, i) => {
      el.setAttribute('tabindex', '-1');
      el.setAttribute('id', `carousel-item-${i + 1}`);
    });
  };

  const scrollIt = slideToShow => {
    const scrollPos = index(slideToShow) * (gallery.scrollWidth / slides.length);

    gallery.scrollLeft = scrollPos;
  };

  const showSlide = dir => {
    console.log(dir);

    const visible = document.querySelectorAll(
      '[aria-label="gallery"] [data-state="visible"]'
    );
    const i = dir === 'previous' ? 0 : 1;
    if (visible.length > 1) {
      scrollIt(visible[i]);
    } else {
      const newSlide =
        i === 0 ? visible[0].previousElementSibling : visible[0].nextElementSibling;
      if (newSlide) {
        scrollIt(newSlide);
      }
    }
  };

  const navState = el => {
    const slideInView = index(el);
    const btnPrev = document.getElementById('previous');
    const btnNext = document.getElementById('next');

    slideInView === slides.length - 1
      ? btnNext.setAttribute('disabled', '')
      : btnNext.removeAttribute('disabled');

    slideInView === 0
      ? btnPrev.setAttribute('disabled', '')
      : btnPrev.removeAttribute('disabled');
  };

  const callback = slides => {
    slides.forEach(slide => {
      const id = slide.target.getAttribute('id');
      const dot = node.querySelector(
        `[aria-label="gallery pagination"] button[data-target="${id}"]`
      );

      slide.target.removeAttribute('data-state', 'visible');
      slide.target.setAttribute('tabindex', '-1');
      dot.removeAttribute('data-state', 'active');

      if (!slide.intersectionRatio > 0) {
        return;
      }

      slide.target.setAttribute('data-state', 'visible');
      slide.target.removeAttribute('tabindex', '-1');
      dot.setAttribute('data-state', 'active');

      navState(slide.target);
    });
  };

  const handlePagination = () => {
    const pagination = document.createElement('nav');

    pagination.classList.add('cluster');
    pagination.setAttribute('aria-label', 'gallery pagination');
    pagination.setAttribute('role', 'list');

    node.setAttribute('data-scrollbar', 'hidden');

    slides.forEach((slide, i) => {
      const btn = document.createElement('button');

      btn.setAttribute('data-target', slide.id);
      btn.setAttribute('type', 'button');
      btn.setAttribute('aria-label', `slide ${i} of ${slides.length}`);
      btn.setAttribute('role', 'listitem');
      btn.classList.add('pill');
      btn.innerHTML = `<span class="visually-hidden">go to slide ${i}</span>`;

      btn.addEventListener('click', e => {
        e.preventDefault();

        const target = e.target.dataset.target;
        const newSlide = node.querySelector(`#${target}`);

        scrollIt(newSlide);
      });

      pagination.appendChild(btn);
    });

    gallery.parentNode.insertBefore(pagination, gallery.nextElementSibling);
  };

  const handleNavigation = () => {
    const navDirections = ['previous', 'next'];
    const btnIcon = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        focusable="false"
        aria-hidden="true"
        fill="currentColor"
      >
        <path
          d="M9.707 18.707l6-6a.999.999 0 0 0 0-1.414l-6-6a.999.999 0 1 0-1.414 1.414L13.586 12l-5.293 5.293a.999.999 0 1 0 1.414 1.414z"
        />
      </svg>
    `;

    navDirections.forEach((direction, i) => {
      const btn = document.createElement('button');

      btn.setAttribute('aria-label', `Move to the ${[direction]} slide`);
      btn.setAttribute('type', 'button');
      btn.id = direction;
      btn.classList.add('icon-button');
      btn.innerHTML = `${btnIcon}<span class="visually-hidden">${direction}</span>`;

      // if (i === 0) {
      //   gallery.parentNode.insertBefore(btn, gallery);
      // } else {
      //   gallery.parentNode.insertBefore(btn, gallery.nextElementSibling);
      // }
      gallery.parentNode.insertBefore(btn, gallery.prevElementSibling);

      btn.addEventListener('click', e => {
        e.preventDefault();
        showSlide(e.target.id, slides);
      });
    });
  };

  const observerSettings = {
    root: gallery,
    rootMargin: '-10px'
  };
  const observer = new IntersectionObserver(callback, observerSettings);

  slides.forEach(slide => {
    return observer.observe(slide);
  });

  node.setAttribute('data-variant', 'js');
  setAttributes(slides);
  handleNavigation();
  handlePagination();
});

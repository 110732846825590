import {picoapp} from 'picoapp';
import {size} from './helpers/dom';
import accordion from './components/accordion';
import burgerMenu from './components/burgerMenu';
import carousel from './components/carousel';
import drawer from './components/drawer';
import map from './components/map';
import tabs from './components/tabs';

const components = {
  accordion,
  burgerMenu,
  map,
  drawer,
  carousel,
  tabs
};

const state = {
  ...size(),
  isNavOpen: false
};

export default picoapp(components, state);

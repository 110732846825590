import Highway from '@dogstudio/highway';
import app from '../app';
import gsap from 'gsap';
// import {map, round} from '../helpers/maths';
import {activeTabIndex} from '../helpers/util';

class ToProject extends Highway.Transition {
  in({from, to, trigger, done}) {
    const backLink = to.querySelector('[data-element="backlink"]');
    const targetScrollArea = to.querySelector('[data-element="scroll-area"]');
    const triggerTitle = trigger.hasAttribute('data-title')
      ? trigger
      : trigger.querySelector('[data-title]');
    const targetFadeIn = to.querySelector('[data-animation="fade-in"]');
    // const toTitle = to.querySelector('.js-title');
    // const toMeta = to.querySelector('.js-meta');
    // const toContent = to.querySelector('.js-content');
    const fromPanel = from.querySelector('[data-element="panel"]');
    // const fromPanel = from.querySelector('.page');
    // const toFooter = to.querySelector('[data-element="footer"]');
    // const fromFooter = from.querySelector('[data-element="footer"]');
    // const toHeroEl = [...to.querySelectorAll('.js-hero')];
    // const fromTop = triggerTitle.getBoundingClientRect().top;
    // const toTop = toTitle.getBoundingClientRect().top;

    // const titleY = Math.round(fromTop - toTop);
    const {windowHeight} = app.getState();
    // const getDuration = round(map(Math.abs(titleY), 0, windowHeight, 1.1, 1.5), 100);
    const getDuration = 0.5;

    const tl = gsap.timeline({
      onComplete: () => {
        // remove previous page container
        from.remove();
        done();
      }
    });

    tl.set(backLink, {autoAlpha: 0});
    // tl.set(toHeroEl, {autoAlpha: 0});
    tl.set(targetFadeIn, {autoAlpha: 0, yPercent: 10});
    tl.set(targetScrollArea, {overflow: 'hidden'});
    tl.set(triggerTitle, {autoAlpha: 0});
    // tl.set(toTitle, {y: titleY});
    // tl.set(toContent, {y: titleY + 100, autoAlpha: 0});
    tl.set(to, {autoAlpha: 1});

    if (fromPanel) {
      tl.to(
        fromPanel,
        {
          // duration: 0.5 * getDuration,
          duration: 0.5,
          autoAlpha: 0,
          ease: 'expo.inOut'
        },
        '<'
      );
    }

    if (targetFadeIn) {
      tl.to(targetFadeIn, {
        duration: 0.15,
        yPercent: 0,
        ease: 'expo.inOut'
      }).to(
        targetFadeIn,
        {
          duration: 0.2,
          autoAlpha: 1,
          ease: 'expo.inOut'
        },
        '<'
      );
    }

    // if (toTitle) {
    //   tl.to(
    //     toTitle,
    //     {
    //       y: 0,
    //       duration: getDuration,
    //       ease: 'expo.inOut'
    //     },
    //     '<'
    //   );
    // }

    // if (toFooter) {
    //   tl.to(
    //     toFooter,
    //     {
    //       yPercent: 100,
    //       duration: getDuration,
    //       ease: 'expo.inOut'
    //     },
    //     '<'
    //   );
    // }

    // if (fromFooter) {
    //   tl.to(
    //     fromFooter,
    //     {
    //       yPercent: 100,
    //       duration: getDuration,
    //       ease: 'expo.inOut'
    //     },
    //     '<'
    //   );
    // }

    if (backLink) {
      tl.to(
        backLink,
        {
          autoAlpha: 1,
          duration: getDuration,
          delay: 0.1,
          ease: 'expo.inOut'
        },
        '<'
      );
    }

    // if (toHeroEl) {
    //   tl.to(
    //     toHeroEl,
    //     {
    //       autoAlpha: 1,
    //       duration: getDuration,
    //       delay: 0.1,
    //       ease: 'expo.inOut'
    //     },
    //     '<'
    //   );
    // }
    // if (toMeta) {
    //   tl.to(
    //     toMeta,
    //     {
    //       autoAlpha: 1,
    //       duration: getDuration,
    //       delay: 0.1,
    //       ease: 'expo.inOut'
    //     },
    //     '<'
    //   );
    // }
    // if (toContent) {
    //   tl.to(
    //     toContent,
    //     {
    //       y: 0,
    //       autoAlpha: 1,
    //       duration: getDuration,
    //       ease: 'expo.inOut'
    //     },
    //     '<'
    //   );
    // }
    if (targetScrollArea) {
      tl.set(targetScrollArea, {clearProps: 'all'});
    }
  }

  out({done}) {
    done();
  }
}

export default ToProject;

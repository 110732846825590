import {index} from '../helpers/dom';

/**
 * Get the X position of an element.
 *
 * @param {number} x
 * @param {number} y
 * @returns {number}
 */
export function getX(x, y) {
  return x.offsetWidth - y.offsetWidth;
}

/**
 * Gets the index of the active Navigation Tab
 *
 * @param {HTMLElement} el
 * @returns {number}
 */
export function activeTabIndex(el) {
  const isActive = el.find(item => item.matches('[data-state="active"]'));
  const activeIndex = index(isActive);

  return activeIndex;
}

import {component} from 'picoapp';
import mapboxgl from 'mapbox-gl';
import data from '../../_data/schedule.json';

export default component(node => {
  const config = {
    style: 'mapbox://styles/psflannery/ck6g9zcaf0hp21ijrqkthj08b',
    accessToken: process.env.MAPBOX_API_KEY,
    center: [-0.1249447, 51.5083888],
    zoom: 12,
    bounds: [
      {lng: -0.2745249, lat: 51.4648445}, // Southwest coordinates
      {lng: -0.0091569, lat: 51.5932057} // Northwest coordinates
    ],
    dimensions: {
      width: node.clientWidth,
      height: node.clientHeight
    }
  };
  const icons = {
    play: `
      <svg data-play height="24" width="24" aria-hidden="true">
        <path d="M8 5v14l11-7z" />
      </svg>
    `,
    pause: `
      <svg data-pause hidden height="24" width="24">
        <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
      </svg>
    `
  };
  const staticImg = `https://api.mapbox.com/styles/v1/psflannery/ck6g9zcaf0hp21ijrqkthj08b/static/${config.center[0]},${config.center[1]},${config.zoom},0/${config.dimensions.width}x${config.dimensions.height}?access_token=${config.accessToken}`;
  let eventIndex = 0;
  let timeoutId = null;
  let playBtn = null; // assign on map load
  let state = null; // assign on map load

  mapboxgl.accessToken = config.accessToken;

  const map = new mapboxgl.Map({
    container: 'map',
    style: config.style,
    center: config.center,
    zoom: config.zoom,
    scrollZoom: false,
    interactive: true,
    maxBounds: config.bounds
  });

  map.on('load', () => {
    map.addSource('places', {
      type: 'geojson',
      data: data
    });

    node.classList.add('map-loaded');

    renderMarkers();
    renderButtons();
    locationList();

    playBtn = document.querySelector('[data-action="play"]');
    state = {
      isPlaying: playBtn.dataset.state !== 'paused'
    };
  });

  map.on('error', e => {
    console.log('Error is', e.error);
  });

  node.parentNode.style.backgroundImage = `url(${staticImg})`;

  // Extract coords from the array of event objects
  const coords = data.features.map(event => event.geometry.coordinates);

  // convert coords to string
  const stringCoords = coords.map(JSON.stringify);

  // remove any duplicates
  const uniqueStringCoords = new Set(stringCoords);

  // convert coord strings back to array
  const uniqueCoords = Array.from(uniqueStringCoords, JSON.parse);

  const renderMarkers = () => {
    uniqueCoords.map((marker, i) => {
      const el = document.createElement('div');

      el.id = 'marker-' + i;
      el.className = 'map__marker';
      el.setAttribute('data-coords', marker);

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el, {offset: [0, -23]}).setLngLat(marker).addTo(map);

      el.addEventListener('click', e => {
        flyToEvent(marker);
        renderPopUp(marker);

        const activeItem = document.querySelectorAll('[data-state="activex"]');
        e.stopPropagation();

        if (activeItem[0]) {
          activeItem[0].removeAttribute('data-state', 'activex');
        }

        // add an active class to the relevant item in the schedule
        // e.target.closest('li').setAttribute('data-state', 'activex');
        // const listing = document.getElementById(`listing-${marker.properties.id}`);
        // listing.classList.add('active');
      });
    });
  };

  const renderPopUp = currentFeature => {
    const popUps = document.getElementsByClassName('mapboxgl-popup');

    if (popUps[0]) popUps[0].remove();

    // get the venue props that match the coords of the marker
    const venues = data.features.filter(
      venue => venue.geometry.coordinates.toString() === currentFeature.toString()
    );

    // grab the first prop in the array
    const venueName = venues[0].properties.Gallery;
    const venueAddress = venues[0].properties.Address;

    // set marker position
    const markerHeight = 50;
    const markerRadius = 25;
    const linearOffset = 25;
    const popupOffsets = {
      'top': [0, 0],
      'top-left': [0, 0],
      'top-right': [0, 0],
      'bottom': [0, -markerHeight],
      'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
      'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
      'left': [markerRadius, (markerHeight - markerRadius) * -1],
      'right': [-markerRadius, (markerHeight - markerRadius) * -1]
    };

    new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      className: 'map__popup',
      offset: popupOffsets
    })
      .setLngLat(currentFeature)
      .setHTML(
        `
        <div class="flow">
          <h3>${venueName}</h3>
          <p class="leading-tight">${venueAddress}</p>
        </div>
      `
      )
      .addTo(map);
  };

  const renderButtons = () => {
    const btn = document.createElement('button');
    const message = 'Take a tour';
    const tooltipId = 'play-map-tooltip';

    btn.classList.add('icon-button', 'tooltip');
    btn.setAttribute('type', 'button');
    btn.setAttribute('aria-labelledby', tooltipId);
    btn.setAttribute('data-alignment', 'right');
    btn.setAttribute('data-action', 'play');
    btn.setAttribute('data-state', 'paused');
    btn.innerHTML = `
      <span class="[ map-player__label ] [ visually-hidden ]">Play</span>
        ${icons.play}
        ${icons.pause}
      <span id="${tooltipId}" class="tooltip__content">${message}</span>
    `;

    node.parentNode.insertBefore(btn, node);

    btn.addEventListener('click', e => {
      e.preventDefault();
      console.log('foo');
      togglePlay(btn, eventIndex);
    });
  };

  const togglePlay = (el, index) => {
    state.isPlaying = !state.isPlaying;

    const playLabel = el.querySelector('.map-player__label');
    const playIcon = el.querySelector('[data-play]');
    const pauseIcon = el.querySelector('[data-pause]');

    if (!state.isPlaying) {
      pauseMap();
      el.dataset.state = 'paused';
      playLabel.textContent = 'Play';
    } else {
      playMap(index);
      el.dataset.state = 'playing';
      playLabel.textContent = 'Pause';
    }

    playIcon.toggleAttribute('hidden');
    pauseIcon.toggleAttribute('hidden');
  };

  const playMap = index => {
    flyToEvent(uniqueCoords[index], 14);
    renderPopUp(uniqueCoords[index]);

    map.once('moveend', function () {
      timeoutId = window.setTimeout(() => {
        let i = (index + 1) % uniqueCoords.length;
        playMap(i);
      }, 3000);
    });
  };

  const pauseMap = () => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }
  };

  const locationList = () => {
    const triggers = document.querySelectorAll('.events__trigger');

    triggers.forEach(trigger => {
      trigger.addEventListener('click', e => {
        e.preventDefault();

        if (state.isPlaying) {
          togglePlay(playBtn, eventIndex);
        }

        const markerCoords = e.target.dataset.coords;
        const lngLatArray = markerCoords.split(',').map(Number);

        const activeItem = document.querySelectorAll('[data-state="activex"]');
        if (activeItem[0]) {
          activeItem[0].removeAttribute('data-state', 'activex');
        }

        e.target.closest('li').setAttribute('data-state', 'activex');

        flyToEvent(lngLatArray);
        renderPopUp(lngLatArray);
      });
    });
  };

  const flyToEvent = (currentFeature, zoom) => {
    map.flyTo({
      center: currentFeature || config.center,
      zoom: zoom || config.zoom + 3,
      essential: true
    });
  };
});

// export default component(node => {
//   const config = {
//     // style: 'mapbox://styles/psflannery/ck6fff3780fqs1imq3zbniwm3',
//     style: 'mapbox://styles/psflannery/ck6g9zcaf0hp21ijrqkthj08b',
//     accessToken: process.env.MAPBOX_API_KEY,
//     center: [-0.1249447, 51.5083888],
//     zoom: 12
//   };
//   const trigger = document.querySelectorAll('.events__trigger');
//   const icons = {
//     play: `
//       <svg data-play height="24" width="24" aria-hidden="true">
//         <path d="M8 5v14l11-7z" />
//       </svg>
//     `,
//     pause: `
//       <svg data-pause hidden height="24" width="24">
//         <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
//       </svg>
//     `
//   };
//   // const staticImg = `https://api.mapbox.com/styles/v1/psflannery/ck6fff3780fqs1imq3zbniwm3/static/-0.1249,51.5084,12,0/600x600?access_token=${config.accessToken}`;
//   const dimensions = {
//     width: node.clientWidth,
//     height: node.clientHeight
//   };

//   const staticImg = `https://api.mapbox.com/styles/v1/psflannery/ck6g9zcaf0hp21ijrqkthj08b/static/-0.1249,51.5084,12,0/${dimensions.width}x${dimensions.height}?access_token=${config.accessToken}`;
//   let eventIndex = 0;
//   let timeoutId = null;

//   // Extract coords from the array of event objects
//   let coords = data.features.map(prop => prop.geometry.coordinates);

//   // convert coords to string
//   let stringCoords = coords.map(JSON.stringify);

//   // remove any duplicates
//   let uniqueStringCoords = new Set(stringCoords);

//   // convert coord strings back to array
//   let uniqueCoords = Array.from(uniqueStringCoords, JSON.parse);

//   // Set bounds
//   const bounds = [
//     [-0.2745249, 51.4648445], // Southwest coordinates
//     [-0.0091569, 51.5932057] // Northeast coordinates
//   ];

//   mapboxgl.accessToken = config.accessToken;

//   const map = new mapboxgl.Map({
//     container: 'map',
//     style: config.style,
//     center: config.center,
//     zoom: config.zoom,
//     scrollZoom: false,
//     interactive: true,
//     maxBounds: bounds
//   });

//   const initMap = () => {
//     data.features.forEach(function (event, i) {
//       event.properties.id = i;
//     });

//     map.on('load', () => {
//       map.addSource('places', {
//         type: 'geojson',
//         data: data
//       });
//       node.classList.add('map-loaded');
//     });

//     map.on('error', e => {
//       console.log('Error is', e.error);
//     });

//     renderMarkers(map);
//   };

//   node.parentNode.style.backgroundImage = `url(${staticImg})`;

//   const btn = document.createElement('button');
//   const message = 'Take a tour';
//   const tooltipId = 'play-map-tooltip';

//   btn.classList.add('icon-button', 'tooltip');
//   btn.setAttribute('type', 'button');
//   btn.setAttribute('aria-labelledby', tooltipId);
//   btn.setAttribute('data-alignment', 'right');
//   btn.setAttribute('data-action', 'play');
//   btn.setAttribute('data-state', 'paused');
//   btn.innerHTML = `
//       <span class="[ map-player__label ] [ visually-hidden ]">Play</span>
//       ${icons.play}
//       ${icons.pause}
//       <span id="${tooltipId}" class="tooltip__content">${message}</span>
//     `;

//   node.parentNode.insertBefore(btn, node);

//   const state = {
//     isPlaying: btn.dataset.state !== 'paused'
//   };

//   btn.addEventListener('click', e => {
//     e.preventDefault();

//     togglePlay(btn, eventIndex);
//   });

//   trigger.forEach(el => {
//     el.addEventListener('click', e => {
//       e.preventDefault();

//       if (state.isPlaying) {
//         togglePlay(btn, eventIndex);
//       }

//       centerMap(e);
//       removeAttributes(trigger, {'data-state': 'active'});
//       e.target.setAttribute('data-state', 'active');
//     });
//   });

//   const togglePlay = (el, index) => {
//     state.isPlaying = !state.isPlaying;

//     const playLabel = el.querySelector('.map-player__label');
//     const playIcon = el.querySelector('[data-play]');
//     const pauseIcon = el.querySelector('[data-pause]');

//     if (!state.isPlaying) {
//       pauseMap();
//       el.dataset.state = 'paused';
//       playLabel.textContent = 'Play';
//     } else {
//       playMap(index);
//       el.dataset.state = 'playing';
//       playLabel.textContent = 'Pause';
//     }

//     playIcon.toggleAttribute('hidden');
//     pauseIcon.toggleAttribute('hidden');
//   };

//   const playMap = index => {
//     flyToEvent(uniqueCoords[index], 14);

//     map.once('moveend', function () {
//       timeoutId = window.setTimeout(() => {
//         // let i = index + 1 === uniqueCoords.length ? 0 : index + 1;
//         let i = (index + 1) % uniqueCoords.length;
//         playMap(i);
//       }, 3000);
//     });
//   };

//   const pauseMap = () => {
//     if (timeoutId) {
//       window.clearTimeout(timeoutId);
//     }
//   };

//   const centerMap = e => {
//     const markerCoords = e.target.dataset.coords;
//     const lngLatArray = markerCoords.split(',').map(Number);

//     e.preventDefault();

//     flyToEvent(lngLatArray, 15);
//   };

//   const flyToEvent = (currentFeature, zoom) => {
//     map.flyTo({
//       center: currentFeature || config.center,
//       zoom: zoom || config.zoom + 1,
//       essential: true
//     });

//     // console.log(Array.from(currentFeature, JSON.parse));
//     // renderPopUps(currentFeature.toString(), true);
//   };

//   // const renderPopUps = (marker, external = false) => {
//   const renderPopUps = marker => {
//     const popUps = document.getElementsByClassName('mapboxgl-popup');
//     console.log(popUps);

//     // get the venue props that match the coords of the marker
//     const venues = data.features.filter(venue => {
//       return venue.geometry.coordinates == marker.dataset.coords;
//     });

//     // grab the first prop in the array
//     const venueName = venues[0].properties.Gallery;
//     const venueAddress = venues[0].properties.Address;
//     console.log(venueName);

//     // set marker position
//     const markerHeight = 50;
//     const markerRadius = 25;
//     const linearOffset = 25;
//     const popupOffsets = {
//       'top': [0, 0],
//       'top-left': [0, 0],
//       'top-right': [0, 0],
//       'bottom': [0, -markerHeight],
//       'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
//       'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
//       'left': [markerRadius, (markerHeight - markerRadius) * -1],
//       'right': [-markerRadius, (markerHeight - markerRadius) * -1]
//     };

//     const popup = new mapboxgl.Popup({
//       offset: popupOffsets,
//       closeButton: false,
//       className: 'map__popup'
//     }).setHTML(
//       `
//         <div class="flow">
//           <h3>${venueName}</h3>
//           <p class="leading-tight">${venueAddress}</p>
//         </div>
//       `
//     );

//     return popup;
//   };

//   const renderMarkers = map => {
//     uniqueCoords.map((marker, i) => {
//       const el = document.createElement('div');

//       el.id = 'marker-' + i;
//       el.className = 'map__marker';
//       el.setAttribute('data-coords', marker);

//       const popup = renderPopUps(el);

//       // make a marker for each feature and add to the map
//       new mapboxgl.Marker(el, {offset: [0, -23]})
//         .setLngLat(marker)
//         .setPopup(popup)
//         .addTo(map);
//     });
//   };

//   // Load the map only when it is fully visible
//   const observerSettings = {
//     rootMargin: '0px',
//     threshold: 1.0
//   };
//   const observer = new IntersectionObserver(initMap, observerSettings);

//   observer.observe(node);
// });

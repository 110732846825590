import Highway from '@dogstudio/highway';
import gsap from 'gsap';
// import { inview } from '../helpers/dom';
import {getX, activeTabIndex} from '../helpers/util';
class Panel extends Highway.Transition {
  in({from, to, done}) {
    const targetPanel = to.querySelector('[data-element="panel"]');
    const prevPanel = from.querySelector('[data-element="panel"]');
    const targetNavItem = [...to.querySelectorAll('[data-element="leaf-nav"]')];
    const prevNavItem = [...from.querySelectorAll('[data-element="leaf-nav"]')];
    const targetNav = [...to.querySelectorAll('[data-element="leaf-link"]')];
    const prevNav = [...from.querySelectorAll('[data-element="leaf-link"]')];
    // const prevFooter = from.querySelector('[data-element="footer"]');
    const backLink = from.querySelector('[data-element="backlink"]');

    const prevIndex = activeTabIndex(prevNavItem);
    const targetIndex = activeTabIndex(targetNavItem);
    const direction = targetIndex - prevIndex;
    // const { windowHeight } = app.getState();

    const tl = gsap.timeline({
      onComplete: () => {
        // remove previous page container
        from.remove();
        done();
      }
    });

    tl.set(to, {autoAlpha: 1});

    // Open pages to the left
    if (direction > 0) {
      tl.set(prevNav.slice(prevIndex + 1, targetIndex + 1), {autoAlpha: 0});
      tl.set(targetPanel, {x: getX(targetNavItem[targetIndex], targetNav[targetIndex])});
      tl.set([targetNav.slice(prevIndex + 1, targetIndex + 1), targetPanel], {
        x: getX(targetNavItem[targetIndex], targetNav[targetIndex]),
        willChange: 'transform'
      });
      tl.set(targetNavItem[prevIndex], {autoAlpha: 0});

      // switch out back link on document pages
      if (backLink) {
        tl.to(
          backLink,
          {
            autoAlpha: 0,
            duration: 0.8,
            ease: 'expo'
          },
          '<'
        );
      }

      // slide down the old footer
      // tl.to(
      //   prevFooter,
      //   {
      //     yPercent: 100,
      //     // duration: 0.4,
      //     // x: getX(targetNavItem[targetIndex], targetNav[targetIndex]),
      //     duration: 0.8,
      //     ease: 'expo.inOut'
      //   },
      //   '<'
      // );

      // fade in the new page
      tl.to(
        targetNavItem[prevIndex],
        {
          autoAlpha: 1,
          duration: 0.8,
          delay: 0.1,
          ease: 'expo.inOut'
        },
        '<'
      );

      tl.to(
        [targetNav.slice(prevIndex + 1, targetIndex + 1), targetPanel].flat(),
        {
          x: 0,
          duration: 0.8,
          ease: 'expo.inOut'
        },
        '<'
      );

      // clear all props
      tl.set([targetNav, targetPanel, targetNavItem].flat(), {
        clearProps: 'all'
      });
    }

    // Open pages to the right
    else if (direction < 0) {
      tl.set(from, {zIndex: 1});
      tl.set(prevNav.slice(targetIndex + 1, prevIndex + 1), {willChange: 'transform'});
      tl.set(targetNav, {autoAlpha: 0});

      // switch out back link on document pages
      if (backLink) {
        tl.to(
          backLink,
          {
            autoAlpha: 0,
            duration: 0.8,
            ease: 'expo.inOut'
          },
          '<'
        );
      }

      // slide down the old footer
      // if (inview(prevFooter, windowHeight)) {
      // tl.to(
      //   prevFooter,
      //   {
      //     yPercent: 100,
      //     // x: getX(prevNavItem[prevIndex], prevNav[prevIndex]), //
      //     // xPercent: 100,
      //     duration: 0.8,
      //     ease: 'expo.inOut'
      //   }
      //   // '<'
      // );
      // }

      // fade in the new page
      tl.to(
        [prevNav.slice(targetIndex + 1, prevIndex + 1), prevPanel],
        {
          x: getX(prevNavItem[prevIndex], prevNav[prevIndex]),
          duration: 0.8,
          ease: 'expo.inOut'
        },
        '<'
      );

      tl.set(targetNav, {autoAlpha: 1});
    }

    // projectIndex
    else {
      const toWorkLink = [...to.querySelectorAll('.js-link')];

      // tl.set(toWorkLink, {
      //   yPercent: 100
      // });
      // tl.set(targetNav, {
      //   autoAlpha: 0
      // });

      tl.to(
        targetNav,
        {
          autoAlpha: 1,
          duration: 0.8,
          delay: 0.1,
          ease: 'expo.inOut'
        },
        '<'
      );
      tl.to(
        from,
        {
          autoAlpha: 0,
          duration: 0.8,
          ease: 'expo.in'
        },
        '<'
      );
      tl.to(
        toWorkLink,
        {
          yPercent: 0,
          duration: 0.8,
          ease: 'expo.inOut'
        },
        '<0.4'
      );
    }
  }

  out({done}) {
    done();
  }
}

export default Panel;

import getFocusableElements from '../helpers/get-focusable-elements';

export default (e, parentEl) => {
  const links = getFocusableElements(parentEl);
  const lastFocusableEl = links[links.length - 1];
  const firstFocusableEl = links[0];
  const tabIsPressed = e.keyCode === 9 || e.key === 'Tab';

  if (!tabIsPressed) return;

  if (e.shiftKey === true) {
    if (document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      e.preventDefault();
    }
  } else {
    if (document.activeElement === lastFocusableEl) {
      firstFocusableEl.focus();
      e.preventDefault();
    }
  }
};
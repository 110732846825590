import { component } from 'picoapp';
import { on } from '../helpers/dom';
import trapFocus from '../helpers/trapFocus';

export default component((node, ctx) => {
  const burgerBtn = node.querySelector('.js-burger');
  const moblieNavLinks = [...node.querySelectorAll('[data-link="mobile"]')];

  const offClick = () => {
    on(moblieNavLinks, 'click', () => {
      if (ctx.getState().isNavOpen) {
        ctx.emit('burger:toggle');
      }
    });
  };

  burgerBtn.addEventListener('click', e => {
    e.preventDefault();
    ctx.emit('burger:toggle');
  });

  node.addEventListener('keydown', e => {
    trapFocus(e, node);

    // if Esc key pressed
    if (e.keyCode === 27) {
      ctx.emit('burger:toggle');
    }
  });

  const observer = new ResizeObserver(() => {
    const burgerMenuVisible = getComputedStyle(node).getPropertyValue(
      '--burger-menu-visible'
    );

    if (parseInt(burgerMenuVisible) === 1) {
      ctx.emit('burger:toggle');
    }
  });

  ctx.on('burger:toggle', toggle);

  function toggle(prevState) {
    switch (prevState.isNavOpen) {
      case true:
        node.setAttribute('data-state', 'closed');
        burgerBtn.setAttribute('aria-label', 'Open menu');
        burgerBtn.setAttribute('aria-expanded', 'false');
        burgerBtn.focus();
        observer.unobserve(node);
        break;
      case false:
      case initial:
        node.setAttribute('data-state', 'active');
        burgerBtn.setAttribute('aria-label', 'Close menu');
        burgerBtn.setAttribute('aria-expanded', 'true');
        observer.observe(node);
        break;
    }

    ctx.hydrate({ isNavOpen: !prevState.isNavOpen });

    offClick();
  }
});